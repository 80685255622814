
import React, { useState } from 'react';
import styles from './index.module.scss';
import RouterConfig from '../../router';
import Sider from '../components/Sider';
import Header from '../components/Header';
import { Layout, message } from 'antd';

const LayoutBase: React.FC = () => {

  const [ collapsed, setCollapsed ] = useState(false);

  message.config({
    duration: 1,
  });

  return (
    <Layout className={styles.layout}>
      <Sider collapsed={collapsed} />
      <Layout>
        <Header onSetCoollapsed={(value: boolean) => setCollapsed(value)} />
        <RouterConfig />
      </Layout>
    </Layout>
  );
};

export default LayoutBase;