import { Menu, Layout } from 'antd';
import styles from './index.module.scss'
import { useSelector } from 'react-redux';
import { StoreBase } from '../../../store/store';

import { useNavigate } from 'react-router-dom';
import { _menu } from '../../../const/menu';
import { useEffect } from 'react';


const SiderBase = ( props: { collapsed: boolean }) => {

    const { Sider } = Layout;

    const global = useSelector((state: StoreBase) => state.global);

    // const dispatch = useDispatch();

    const navigate = useNavigate();

    // const location = useLocation();

    const jumpTo = ({ item }: any) => {
        // console.log(key , global.activeMenuKey);
        // if( key !== global.activeMenuKey ){
        // dispatch(setActiviMenuKey(key))
        // dispatch(setBreadcrumb(handlebreadcrumb(key)));
        navigate(item.props.href)
        // }
    }

    // 监听路由变化
    // useEffect(() => {
    //     const _key = selectMenuByPathName(location.pathname);
    //     // 先判断是否有这个路由
    //     if( _key ){
    //         dispatch(setActiviMenuKey(_key))
    //         dispatch(setBreadcrumb(handlebreadcrumb(_key)));
    //     }
    // }, [location])

    // useEffect(() => {
    //     dispatch(setActiviMenuKey('0-1'))
    //     dispatch(setBreadcrumb(handlebreadcrumb('0-1')));
    // }, [])

    // useEffect(() => {
    //     console.log(global.activeMenuKey)
    // }, [global.activeMenuKey])

    return (
        <Sider trigger={null} collapsible collapsed={props.collapsed} width={220}>
            <div className={styles.logoVeritical}>
                <img src='//multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/Xempower-logo-.png' alt='logo' />
            </div>
            { global.activeMenuKey && <Menu theme="dark" mode="inline" defaultOpenKeys={[global.activeMenuKey[0]]} defaultSelectedKeys={[global.activeMenuKey]} selectedKeys={[global.activeMenuKey]} items={_menu} onClick={jumpTo} /> }
        </Sider>
    )
}

export default SiderBase;