// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_btn__We7GH {
  width: 64px !important;
  height: 64px;
  font-size: 16px;
}

.Header_header__o17Zo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 0;
}
.Header_header__o17Zo div {
  display: flex;
  align-items: center;
}
.Header_header__o17Zo .Header_breadcrumb__\\+WSmK {
  padding: 0;
}
.Header_header__o17Zo .Header_headerBreadcrumb__1-o\\+W {
  display: flex;
  padding-left: 24px;
  align-content: center;
}
.Header_header__o17Zo .Header_info__TAEUh span {
  margin-left: 20px;
}

.Header_dropdown__fKWs\\+ {
  height: 30px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/views/components/Header/index.module.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,YAAA;EACA,eAAA;AACJ;;AACA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AAEJ;AADI;EACI,aAAA;EACA,mBAAA;AAGR;AADI;EACI,UAAA;AAGR;AADI;EACI,aAAA;EACA,kBAAA;EACA,qBAAA;AAGR;AAAQ;EACI,iBAAA;AAEZ;;AAEA;EACI,YAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AACJ","sourcesContent":[".btn{\n    width: 64px!important;\n    height: 64px;\n    font-size: 16px;\n}\n.header{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0 16px 0 0;\n    div{\n        display: flex;\n        align-items: center;\n    }\n    .breadcrumb{\n        padding: 0;\n    }\n    .headerBreadcrumb{\n        display: flex;\n        padding-left: 24px;\n        align-content: center;\n    }\n    .info{\n        span{\n            margin-left: 20px;\n        }\n    }\n}\n.dropdown{\n    height: 30px;\n    padding: 0 12px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `Header_btn__We7GH`,
	"header": `Header_header__o17Zo`,
	"breadcrumb": `Header_breadcrumb__+WSmK`,
	"headerBreadcrumb": `Header_headerBreadcrumb__1-o+W`,
	"info": `Header_info__TAEUh`,
	"dropdown": `Header_dropdown__fKWs+`
};
export default ___CSS_LOADER_EXPORT___;
