import { createSlice } from '@reduxjs/toolkit';

export interface UserConfig{
  userId: number;
  token : string | null;
}

const _state: UserConfig = {
  userId: 0,
  token: localStorage.getItem('_token')
}

export const User = createSlice({
  name: 'user',
  initialState: _state,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem('_token', action.payload)
    },
    removeToken: (state) => {
      state.token = '';
      localStorage.removeItem('_token');
    },
  },
});

// 为每个 case reducer 函数生成 Action creators
export const { setToken, removeToken } = User.actions;

export default User.reducer;