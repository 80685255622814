import { createSlice } from '@reduxjs/toolkit';

export interface GlobalConfig{
  activeMenuKey: string;
  hasBack?: boolean;
  breadcrumb: Array<{ title: any }>;
}

const _state: GlobalConfig = {
    activeMenuKey: '',
    breadcrumb: [],
    hasBack: false,
}

export const Global = createSlice({
  name: 'global',
  initialState: _state,
  reducers: {
    setActiviMenuKey: (state, action) => {
      state.activeMenuKey = action.payload;
    },
    setBreadcrumb: (state, action) => {
      state.breadcrumb = action.payload;
    },
    setbackVisibility: (state, action) => {
      state.hasBack = action.payload;
    },
  },
});

// 为每个 case reducer 函数生成 Action creators
export const { setActiviMenuKey, setBreadcrumb, setbackVisibility } = Global.actions;

export default Global.reducer;