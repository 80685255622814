// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_login__45xcb {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #292a3a, #536976);
}
.Login_login__45xcb > div {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  width: 300px;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.4);
}
.Login_login__45xcb h1 {
  margin-bottom: 20px;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/views/Login/index.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,qDAAA;AACJ;AAAI;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,oCAAA;EACA,mCAAA;UAAA,2BAAA;EACA,YAAA;EACA,mBAAA;EACA,0CAAA;AAER;AAAI;EACI,mBAAA;EACA,WAAA;AAER","sourcesContent":[".login{\n    height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: linear-gradient(135deg, #292a3a, #536976);\n    > div{\n        padding: 20px 10px;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        justify-content: center;\n        background: rgba($color: #fff, $alpha: 0.2);\n        backdrop-filter: blur(20px);\n        width: 300px;\n        border-radius: 20px;\n        border: 2px solid rgba($color: #fff, $alpha: 0.4);\n    }\n    h1{\n        margin-bottom: 20px;\n        color: #fff;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `Login_login__45xcb`
};
export default ___CSS_LOADER_EXPORT___;
