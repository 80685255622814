// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_fadeIn__yDMMC {
  opacity: 0;
  height: calc(100vh - 88px);
  animation: Layout_fadeIn__yDMMC 0.4s ease-in-out forwards;
}

@keyframes Layout_fadeIn__yDMMC {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/Layout/index.module.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,0BAAA;EAEA,yDAAA;AAAJ;;AAGA;EACI;IACI,UAAA;EAAN;EAEE;IACI,UAAA;EAAN;AACF","sourcesContent":[".fadeIn{\n    opacity: 0;\n    height: calc(100vh - 88px);\n    // transition: opacity .2s ease-in-out;\n    animation: fadeIn .4s ease-in-out forwards;\n}\n\n@keyframes fadeIn {\n    from {\n        opacity: 0;\n    }\n    to {\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fadeIn": `Layout_fadeIn__yDMMC`
};
export default ___CSS_LOADER_EXPORT___;
