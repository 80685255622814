import { configureStore } from '@reduxjs/toolkit';
import User, { UserConfig } from './user';
import Global, { GlobalConfig } from './global';

export interface StoreBase{
  user: UserConfig;
  global: GlobalConfig
}

export default configureStore({
  reducer: {
    user: User,
    global: Global,
  },
});