import {
    TransactionOutlined,
    CrownOutlined,
    MessageOutlined,
    SolutionOutlined,
} from '@ant-design/icons';

export const _menu = [{
    key: '0',
    icon: <CrownOutlined />,
    label: '赛事管理',
    children: [{
        key: '0-0',
        label: '赛事列表',
        href : '/games'
    }
    // ,{
    //     key: '0-1',
    //     label: '报名表',
    //     href : '/project'
    // }
    ]
},{
    key: '1',
    icon: <SolutionOutlined />,
    label: '运动员管理',
    href : '/sports'
    // children: [{
    //     key: '1-0',
    //     label: '运动员编辑',
    // }]
},
{
    key: '2',
    icon: <TransactionOutlined />,
    label: '订单管理',
    children: [{
        key: '2-0',
        label: '全部订单',
        href : '/bill'
    },{
        key: '2-1',
        label: '退款订单',
        href : '/bill/refund'
    },{
        key: '2-2',
        label: '发票订单',
        href : '/bill/bill'
    }]
},
{
    key: '3',
    icon: <MessageOutlined />,
    label: '联系我们',
    children: [{
        key: '3-0',
        label: '意见建议',
        href : '/opinions'
    },{
        key: '3-1',
        label: '信息修改',
        href : '/opinions/update'
    }]
}]