// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*, *::before, *::after {
  margin: 0;
  box-sizing: border-box;
  font-family: 'PingFang-SC-Regular', 'PingFang-SC', 'sans-serif';
}
html, body {
  height: 100%;
  overflow-x: hidden;
}
ul,li{
  list-style: none;
  padding: 0;
}
a{
  color: #411108;
  text-decoration: none;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
input, button, textarea, select {
  font: inherit;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
#root, #__next {
  isolation: isolate;
}
#root{
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/reset.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,sBAAsB;EACtB,+DAA+D;AACjE;AACA;EACE,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,UAAU;AACZ;AACA;EACE,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,gBAAgB;EAChB,mCAAmC;AACrC;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,aAAa;AACf;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;AACd","sourcesContent":["*, *::before, *::after {\n  margin: 0;\n  box-sizing: border-box;\n  font-family: 'PingFang-SC-Regular', 'PingFang-SC', 'sans-serif';\n}\nhtml, body {\n  height: 100%;\n  overflow-x: hidden;\n}\nul,li{\n  list-style: none;\n  padding: 0;\n}\na{\n  color: #411108;\n  text-decoration: none;\n}\nbody {\n  line-height: 1.5;\n  -webkit-font-smoothing: antialiased;\n}\nimg, picture, video, canvas, svg {\n  display: block;\n  max-width: 100%;\n}\ninput, button, textarea, select {\n  font: inherit;\n}\np, h1, h2, h3, h4, h5, h6 {\n  overflow-wrap: break-word;\n}\n#root, #__next {\n  isolation: isolate;\n}\n#root{\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
