import React, { Suspense } from 'react';
import { useRoutes, Navigate } from "react-router-dom";
import FadeIn from '../Layout/fadein';
import { Card } from 'antd';
import styles from '../views/Layout/index.module.scss'
import { useDispatch } from 'react-redux';
// import { StoreBase } from '../store/store';
import { setActiviMenuKey, setbackVisibility } from '../store/global';
// import { selectMenuByPathName } from '../util/util'

// 创建路由
const routes: Array<any> = [
  // {
  //   path: "/",
  //   element: (<Games></Games>)
  // },
  {
    path: "/games",
    children: [{
      path: '/games',
      element: () => import('../views/Games'),
      meta: {
        title: "赛事列表",
        key: '0-0'
      },
    },{
      path: 'create',
      element: () => import('../views/Games/createGame'),
      meta: {
        title: "新增赛事",
        key: '0-0',
        hasBack: true,
      },
    },{
      path: 'edit/:id',
      element: () => import('../views/Games/createGame'),
      meta: {
        title: "编辑赛事",
        key: '0-0',
        hasBack: true,
      },
    },{
      path: 'official/:id',
      element: () => import('../views/Games/official'),
      meta: {
        title: "官员",
        key: '0-0',
        hasBack: true,
      },
    },{
      path: 'indicates/:id',
      element: () => import('../views/Games/indicates'),
      meta: {
        title: "报名表",
        key: '0-0',
        hasBack: true,
      },
    }]
  },
  {
    path: "/project",
    children: [{
      path: "/project/:match_id",
      element: () => import('../views/Project'),
      meta: {
        title: "项目管理",
        key: '0-0',
        hasBack: true,
      },
    },{
      path: 'create/:match_id',
      // element: <CreateGames />,
      element: () => import('../views/Project/createProject'),
      meta: {
        title: "新增项目",
        key: '0-0',
        hasBack: true,
      },
    },{
      path: 'edit/:match_id/:id',
      // element: <CreateGames />,
      element: () => import('../views/Project/createProject'),
      meta: {
        title: "编辑项目",
        key: '0-0',
        hasBack: true,
      },
    },{
      path: 'publicparams/:match_id/:group_id',
      // element: <CreateGames />,
      element: () => import('../views/Project/publicParams'),
      meta: {
        title: "通用字段",
        key: '0-0',
        hasBack: true,
      },
    },{
      path: 'customparams/:match_id/:group_id',
      // element: <CreateGames />,
      element: () => import('../views/Project/customParams'),
      meta: {
        title: "自定义字段",
        key: '0-0',
        hasBack: true,
      },
    },{
      path: 'customparams/:match_id/:group_id/:id',
      // element: <CreateGames />,
      element: () => import('../views/Project/customParams'),
      meta: {
        title: "自定义字段",
        key: '0-0',
        hasBack: true,
      },
    },{
      path: 'indicates/:match_id/:id',
      // element: <CreateGames />,
      element: () => import('../views/Project/indicates'),
      meta: {
        title: "报名字段",
        key: '0-0',
        hasBack: true,
      },
    }]
  },
  {
    path: "/sports",
    element: () => import('../views/Sportsman'),
    meta: {
      title: "运动员管理",
      key: '1',
    },
  },
  // 和后端nginx order 冲突了 所以改名
  {
    path: "/bill",
    children: [{
      path: "/bill",
      element: () => import('../views/Order/all'),
      meta: {
        title: "全部订单",
        key: '2-0',
      },
    },{
      path: "/bill/refund",
      element: () => import('../views/Order/refund'),
      meta: {
        title: "退款订单",
        key: '2-1',
      },
    },{
      path: "/bill/bill",
      element: () => import('../views/Order/bill'),
      meta: {
        title: "发票订单",
        key: '2-2',
      },
    }]
  },
  {
    path: "/opinions",
    meta: {
      title: "联系我们",
    },
    children: [{
      path: "/opinions",
      element: () => import('../views/Opinions'),
      meta: {
        title: "意见建议",
        key: '3-0',
      },
    },
    {
      path: "/opinions/update",
      element: () => import('../views/Opinions'),
      meta: {
        title: "信息修改",
        key: '3-1',
      },
    }]
  },
  {
    path:"/",
    redirect: "/games",
  },
  {
    path: '*',
    redirect: "/games",
  }
];

interface routeType {
  path: string;
  element?: any;
  children?: Array<any>;
  redirect?: string;
  meta?: {
    title?: string;
    needLogin?: boolean;
    hasBack?: boolean;
  }
}

function renderRoutes(routes: Array<routeType>) {

  return routes.map((item: routeType) => {

      interface resType extends routeType{
          element?: any
      }

      let res: resType = { ...item };

      // children
      if ( item?.children ) {
        res.children = renderRoutes(item.children);
      }

      if( item?.redirect ){
        res.element = <Navigate to={item.redirect} replace />
      }
      
      // component
      if ( item?.element ) {
          const Component = React.lazy(item.element);
          res.element = (
            <Suspense>
              <BeforeEach route={item}>
                <Card title={item?.meta?.title} className={styles.content}>
                  <Component />
                </Card>
              </BeforeEach>
            </Suspense>
          );
      }

      return res;
  })
}

function BeforeEach( props: { route: any, children: any }) {

  const dispatch = useDispatch();


  setTimeout(() => {

    const _key = props.route.meta ? props.route.meta.key : '';

    // console.time()
    //  先判断是否有这个路由
    if( _key ){
        dispatch(setActiviMenuKey(_key))
        // dispatch(setBreadcrumb(handlebreadcrumb(_key)));
    }

    dispatch(setbackVisibility(Boolean(props?.route?.meta?.hasBack)));

  }, 0);

  // console.timeEnd();

  // dispatch(setBreadcrumb(handlebreadcrumb(_key)));

  // if ( props?.route?.meta?.title ) {
  //     document.title = props.route.meta.title;
  // }
  
  
  if( props?.route?.meta?.needLogin ){
      // 看是否登录
      // const navigate = useNavigate();
      // navigate('/login');
  }

  return <FadeIn>{props.children}</FadeIn>;
}

export default function Routes() {
  const element = useRoutes( renderRoutes( routes ) );
  return element;
}

// const useRouter = () => useRoutes(routes);
// export default useRouter;