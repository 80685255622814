
import React from 'react';
import { Button, Form, Input, message } from 'antd';
import styles from './index.module.scss';
import { loginApi } from './api';
import { useDispatch } from 'react-redux';
import { setToken } from '../../store/user';

const Login: React.FC = () => {

    const dispatch = useDispatch();

    const onFinish = (values: any) => {

        loginApi('/admin/login', values).then( (res: any) => {
            message.success('登陆成功');
            dispatch(setToken(res.token))
        })

    };
    
    const onFinishFailed = (errorInfo: any) => {
      console.log('Failed:', errorInfo);
    };
    

    return (
        <div className={styles.login}>
            <div>
                <h1>街舞报名系统</h1>
                <Form 
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item name="username" rules={[{ required: true, message: '请输入账户' }]}>
                        <Input placeholder='账户' />
                    </Form.Item>
    
                    <Form.Item name="password" rules={[{ required: true, message: '请输入密码' }]}>
                        <Input.Password placeholder='密码' />
                    </Form.Item>
    
                    <Form.Item>
                        <Button block type="primary" htmlType="submit">登录</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Login;