import axios, { AxiosRequestConfig } from 'axios'
import { message } from 'antd';

const env = process.env.NODE_ENV;
console.log('env: ------', env);

const handleBaseUrl = (type: string) => {
    switch( type ){
        case 'development' : return 'https://hiphop-register.outest.imagiclouds.com';
        case 'production'  : return 'https://hiphop-register.xempower.cn/';
        default            : return 'https://hiphop-register.outest.imagiclouds.com';
    }
}

export const baseURL = handleBaseUrl(env);

const instance = axios.create({
    baseURL: baseURL,
    timeout: 20000,
    headers: {
        'Content-Type': 'application/json',
    },
});

// messagekey 有就不在触发了
let messageKey = 0;

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    
    // 在这里通过本地存储或状态管理获取 token
    const token = localStorage.getItem('_token');
    
    // 如果存在 token,在请求头中携带
    if (token) { 
      config.headers.Authorization = `Bearer ${token}`;
    }

    message.destroy(messageKey);

    !messageKey && message.open({
        type: 'loading',
        key: (messageKey = 1, messageKey),
        content: 'loading for...',  
        duration: 0,
    });

    return config;
  }, function (error) {
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {

    if ( response.status !== 200 || response.data.code !== 200 ) {  

        messageKey && message.destroy(messageKey);
        
        message.open({
            type: 'error',
            key: (messageKey = 1, messageKey),
            content: response.data.message,
            duration: 2,
        });
        return Promise.reject(response);        
    }

    message.destroy(messageKey);

    messageKey = 0;

    return response.data.data;
  }, function (error) {
    
    message.destroy(messageKey);

    messageKey = 0;

    // 临时测试
    return Promise.resolve(error.response);

    // return Promise.reject(error);
    // if (error.response.status) {
    //     switch (error.response.status) {
    //         // 403 token过期
    //         case 403: message.error('登录过期，请重新登录');break;
    //         // 404请求不存在
    //         case 404: message.error('网络请求不存在');break;
    //         // 其他错误，直接抛出错误提示
    //         default: message.error(error);
    //     }
        
    // }

});


const getRequest = ( url: string, data: object, config?: AxiosRequestConfig<any> | undefined ) => {
    return instance.get( url, {
        params: data,
    })
}

export const get = getRequest;
export const post = instance.post;
export default instance;