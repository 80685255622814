import type { MenuProps } from 'antd';
import { useSelector } from 'react-redux';
import { StoreBase } from '../../../store/store';
import { Button, Layout, theme, Avatar, Dropdown, Breadcrumb } from 'antd';
import {
    UserOutlined,
    LeftOutlined
    // MenuUnfoldOutlined,
    // MenuFoldOutlined
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { removeToken } from '../../../store/user';
import styles from './index.module.scss';

const HeaderBase = (props: {onSetCoollapsed : (value: boolean) => void}) => {

    const { Header } = Layout;

    const dispatch = useDispatch();

    // const [collapsed, setCollapsed] = useState(false);

    const { token: { colorBgContainer } } = theme.useToken();

    const global = useSelector((state: StoreBase) => state.global);

    const outoff = () => {
        dispatch(removeToken());
        window.location.href = '/';
    }

    const items: MenuProps['items'] = [{
        key: '1',
        label: <div className={styles.dropdown} onClick={outoff}>退出</div>
    }]

    // const onSetCoollapsed = (value: boolean) => {
    //   setCollapsed(value);
    //   props.onSetCoollapsed(value);
    // }

    return (
        <Header className={styles.header} style={{ background: colorBgContainer }}>
            <div className={styles.headerBreadcrumb}>
                { global.hasBack && <Button icon={<LeftOutlined />} type="link" onClick={() => window.history.back()}>返回</Button> }
                {/* <Button className={styles.btn} type="text" icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} onClick={() => onSetCoollapsed(!collapsed)} /> */}
                <Breadcrumb className={styles.breadcrumb} items={global.breadcrumb} separator=">" />
            </div>
            <div className={styles.info}>
                <span>管理员</span>
                <Dropdown menu={{ items }} placement="bottomRight">
                    <Avatar size={34} icon={<UserOutlined />} />
                </Dropdown>
            </div>
        </Header>
    )
}

export default HeaderBase;