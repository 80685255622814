
import React from 'react';
import { useSelector } from 'react-redux';
import { StoreBase } from '../store/store';
import { BrowserRouter } from "react-router-dom";
import Login from '../views/Login';
import LayoutBase from '../views/Layout';

const Layout: React.FC = () => {

    const user = useSelector((state: StoreBase) => state.user);
    // 判断是否登录

    return user.token ? <BrowserRouter><LayoutBase /></BrowserRouter> : <Login />;
};

export default Layout;