// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_layout__H43nd {
  height: 100%;
}
.Layout_layout__H43nd .Layout_logoVeritical__qYsHs {
  padding-top: 50px;
}
.Layout_layout__H43nd .Layout_breadcrumb__SrafM {
  margin: 24px 16px 0;
  padding: 0;
}
.Layout_layout__H43nd .Layout_content__yO8Xs {
  margin: 24px 16px;
  height: calc(100vh - 88px);
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/views/Layout/index.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAAI;EACI,iBAAA;AAER;AAAI;EACI,mBAAA;EACA,UAAA;AAER;AAAI;EACI,iBAAA;EACA,0BAAA;EACA,gBAAA;AAER","sourcesContent":[".layout{\n    height: 100%;\n    .logoVeritical{\n        padding-top: 50px;\n    }\n    .breadcrumb{\n        margin: 24px 16px 0;\n        padding: 0;\n    }\n    .content{\n        margin: 24px 16px;\n        height: calc(100vh - 88px);\n        overflow-y: auto;\n        // padding: 0 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `Layout_layout__H43nd`,
	"logoVeritical": `Layout_logoVeritical__qYsHs`,
	"breadcrumb": `Layout_breadcrumb__SrafM`,
	"content": `Layout_content__yO8Xs`
};
export default ___CSS_LOADER_EXPORT___;
